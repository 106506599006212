<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        v-model="editedItem.DokumentTypeID"
        item-text="Description"
        item-value="Value"
        :items="DokumentTypeItems"
        label="Dokument type"
      ></v-autocomplete>
      <v-autocomplete
        v-model="editedItem.VedleggDescTypeID"
        item-text="Description"
        item-value="Value"
        :items="VedleggDescTypeItems"
        label="Beskrivelsestype"
      ></v-autocomplete>
      <v-autocomplete
        v-model="editedItem.AktuellForTypeID"
        item-text="Description"
        item-value="Value"
        :items="AktuellForTypeItems"
        label="Aktuell for"
      ></v-autocomplete>
      <wtDatetime v-model="editedItem.Dato" label="Dato" onlyDate></wtDatetime>
      <v-text-field
        v-model="editedItem.Kommentar"
        label="Kommentar"
        maxlength="4000"
      ></v-text-field>
      <wtMultiSelect
        v-model="editedItem.itemsAvvikBehandlingVedlegg"
        item-text="Description"
        item-value="Value"
        :items="AvvikBehandlingItems"
        label="Behandlingsvedlegg"
      ></wtMultiSelect>
      <wtMultiSelect
        v-model="editedItem.itemsAvvikVedlegg"
        item-text="Description"
        item-value="Value"
        :items="AvvikItems"
        label="Beskrivelses vedlegg"
      ></wtMultiSelect>
      <wtMultiSelect
        v-model="editedItem.itemsErfaringVedlegg"
        item-text="Description"
        item-value="Value"
        :items="ErfaringItems"
        label="Erfarings vedlegg"
      ></wtMultiSelect>
      <wtMultiSelect
        v-model="editedItem.itemsTaskVedlegg"
        item-text="Description"
        item-value="Value"
        :items="TaskItems"
        label="Oppgave vedlegg"
      ></wtMultiSelect>
    </v-col>
  </v-row>
</template>
<script>
import KeyValue from "../global/KeyValue";
import wtDatetime from "../components/wtDatetime.vue";
import wtMultiSelect from "../components/wtMultiSelect.vue";

export default {
  extends: KeyValue,
  name: "dlgVedleggMeta",
  components: {
    wtDatetime,
    wtMultiSelect,
  },

  model: {
    prop: "editedItemIn",
    event: "input",
  },

  props: {
    editedItemIn: Object,
    editMode: Number, //v-if="editMode !== -1" - Not in "new"
  },

  computed: {
    editedItem() {
      return this.editedItemIn
    }
  },

  created() {
    this.GetAktuellForTypeCode();
    this.GetDokumentTypeCode();
    this.GetVedleggDescTypeCode();
    this.GetAvvikBehandlingCode();
    this.GetAvvikCode();
    this.GetErfaringCode();
    this.GetTaskCode();
  },

  methods: {
    output: function () {
      // pass back to the parent
      this.$emit("input", this.editedItem);
    },
  },
};
</script>
